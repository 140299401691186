<template>
    <v-form ref="form" v-model="valid" @submit.prevent="send">
        <v-layout wrap>
            <v-flex xs12 md4>
                <v-switch v-model="input.incharge" label="INCHARGE" class="my-0 py-0" />
            </v-flex>
            <v-flex xs12 md4>
                <v-switch v-model="input.hubject" label="Integração com Hubject" class="my-0 py-0" />
            </v-flex>
            <v-flex xs12>
                <v-alert border="left" colored-border color="info" elevation="5">
                    <h5 class="info--text">Potência</h5>
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-text-field v-model="input.powerMax" :rules="[rules.required, rules.range(1, 180)]" label="Potência máxima" suffix="kW" type="number" required />
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-switch v-model="input.powerControl" label="Liberação de potência ao conectar" class="my-0 py-0" />
                        </v-flex>
                        <v-flex xs12 md3 v-if="input.powerControl">
                            <v-text-field v-model="input.powerFree" :rules="[rules.required, rules.range(1, 10)]" label="Potência Grátis" suffix="kW" type="number" required />
                        </v-flex>
                        <v-flex xs12 md3 v-if="input.powerControl">
                            <v-text-field v-model="input.powerFreeTime" :rules="[rules.range(1, 60)]" label="Tempo Grátis" suffix="minutos" type="number" required />
                        </v-flex>
                    </v-layout>
                </v-alert>
            </v-flex>
            <v-flex xs12>
                <v-alert border="left" colored-border color="info" elevation="5">
                    <h5 class="info--text">Política de preço</h5>
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-select v-model="input.pricePolicy" :items="policies" label="Política" class="my-0 py-0" clearable />
                        </v-flex>
                        <v-flex xs12 md6 v-if="input.pricePolicy">
                            <v-text-field v-model="input.price" :rules="[rules.required, rules.money]" label="Valor na Ativação" v-mask="['#.##', '##.##', '###.##']"
                                return-masked-value prefix="R$" type="number" required />
                        </v-flex>
                        <v-flex xs12 md6 v-if="input.pricePolicy == 'PAYMENT_PER_CONSUMPTION'">
                            <v-text-field v-model="input.priceKwh" :rules="[rules.required, rules.money]" label="Preço por kWh" v-mask="['#.##', '##.##']" return-masked-value
                                prefix="R$" type="number" required />
                        </v-flex>
                    </v-layout>
                </v-alert>
            </v-flex>
            <v-flex xs12>
                <v-alert border="left" colored-border color="info" elevation="5">
                    <h5 class="info--text">Mercado Pago</h5>
                    <v-layout wrap>
                        <v-flex xs12 md6>
                            <v-text-field v-model="input.mpPublic" label="Chave pública" />
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-text-field v-model="input.mpPrivate" label="Chave privada" />
                        </v-flex>
                    </v-layout>
                </v-alert>
            </v-flex>
            <v-flex xs12>
                <v-alert border="left" colored-border color="info" elevation="5">
                    <h5 class="info--text">Stripe</h5>
                    <v-layout wrap>
                        <v-flex xs12 md6>
                            <v-text-field v-model="input.stripePublic" label="Chave pública" />
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-text-field v-model="input.stripePrivate" label="Chave privada" />
                        </v-flex>
                    </v-layout>
                </v-alert>
            </v-flex>
            <v-flex xs12>
                <v-btn style="width: 100%" color="success" @click="send" :loading="sending" :disabled="sending || !valid">Salvar</v-btn>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import policies from "@/assets/json/pricePolicies.json";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            input: null,
            sending: false,
            policies,
        };
    },

    created() {
        this.input = { ...this.data };
    },

    methods: {
        send() {
            if (this.$refs.form.validate()) {
                this.sending = true;
                this.$http
                    .put("api/v2/car/configuration", this.input.id, this.input)
                    .then((result) => {
                        this.sending = false;
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Dados salvo com sucesso.");
                    })
                    .catch((error) => {
                        this.sending = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao salvar dados.");
                    });
            }
        },
    },
};
</script>